import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { RouteName } from '@/router/route-name'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: RouteName.SUPPLIES }
  },
  {
    path: '/supplies',
    name: RouteName.SUPPLIES,
    component: () => import(/* webpackChunkName: "MySupplies" */'@/views/MySupplies/MySupplies.vue')
  },
  {
    path: '/supply/:supplyId',
    name: RouteName.SUPPLY_REVIEW,
    props: true,
    component: () => import(/* webpackChunkName: "SupplyReview" */'@/views/SupplyReview/SupplyReview.vue')
  },
  {
    path: '/offers',
    name: RouteName.OFFERS,
    component: () => import(/* webpackChunkName: "MyOffers" */'@/views/MyOffers/MyOffers.vue')
  },
  {
    path: '/offer/:offerId',
    name: RouteName.OFFER_REVIEW,
    props: true,
    component: () => import(/* webpackChunkName: "OfferReview" */'@/views/OfferReview/OfferReview.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
