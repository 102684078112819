import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/plugins/vue-i18n'
import { createPinia } from 'pinia'

Vue.config.productionTip = false

const requirePlugin = require.context(
  '@/plugins',
  false,
  /\w+\.(ts|js)$/
)
requirePlugin.keys().forEach(pluginName => {
  const plugin = requirePlugin(pluginName)
  if (plugin.setup) {
    plugin.setup(Vue)
  }
})

new Vue({
  i18n,
  router,
  pinia: createPinia(),
  render: h => h(App)
}).$mount('#app')
