











import 'xway-ui/styles/index.styl'
import { defineComponent } from '@vue/composition-api'
import { useStore } from '@/store/store'

export default defineComponent({
  setup () {
    const store = useStore()

    return {
      store
    }
  }
})
