import { defineStore } from 'pinia'

export const useStore = defineStore({
  id: 'root',
  state: () => ({
    user: {
      name: 'Name',
      email: 'Email'
    }
  })
})
