import Vue from 'vue'
import {
  XLayout,
  XPage,
  XCard,
  XLabel,
  XDropdown,
  XInput,
  XSelect,
  XDivider,
  XPlacehandler,
  XPaginator,
  XLink,
  XIcon,
  XImg,
  XTag,
  XRating
} from 'xway-ui'

Vue.component('XLayout', XLayout)
Vue.component('XPage', XPage)
Vue.component('XCard', XCard)
Vue.component('XLabel', XLabel)
Vue.component('XDropdown', XDropdown)
Vue.component('XInput', XInput)
Vue.component('XSelect', XSelect)
Vue.component('XDivider', XDivider)
Vue.component('XPlacehandler', XPlacehandler)
Vue.component('XPaginator', XPaginator)
Vue.component('XLink', XLink)
Vue.component('XIcon', XIcon)
Vue.component('XImg', XImg)
Vue.component('XTag', XTag)
Vue.component('XRating', XRating)
