import ru from './ru.yaml'
import en from './en.yaml'

import uiRu from 'xway-ui/locales/ru.yaml'
import uiEn from 'xway-ui/locales/en.yaml'

export const messages = {
  ru: {
    ...ru,
    ...uiRu
  },
  en: {
    ...en,
    ...uiEn
  }
}
